<template>
	<div class="wallet_box">
		<div class="wallet">
			<div class="title">{{ $t('card_detail.breadcrumb3') }}</div>
			<div class="w_body">
				<div class="w_left">
					<div class="card1">
						<div class="number">
							{{ cardInfo.cardNo }}
						</div>
						<div class="date">
							{{ cardInfo.expYear }}/
							{{ cardInfo.expMonth }}
						</div>
						<div class="cvv">
							{{ cvv }}
						</div>
					</div>
					<!-- <img src="../../assets/card/detail_card1.png" alt=""> -->
					<!-- <img src="../../assets/card/detail_card2.png" alt=""> -->
				</div>
				<div class="w_right">
					<div class="font">{{ $t('card_detail.font') }}</div>
					<div class="usdt">
						{{ cardInfo.balance }}
						<span>USD</span>
					</div>
					<div class="button_box">
						<button @click="toTopup">
							<img src="../../assets/card/icon4.png" alt="" />
							<span>{{ $t('card_detail.button1') }}</span>
						</button>
						<button class="button1" @click="getCvv">
							<img src="../../assets/card/icon2.png" alt="" />
							<span>{{ $t('card_detail.button2') }}</span>
						</button>
						<button @click="dialogVisible = true">
							<img src="../../assets/card/icon3.png" alt="" />
							<span>{{ $t('card_detail.button3') }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="history">
			<div class="h_title">
				<span>{{ $t('card_detail.h_title') }}</span>
				<div>
					<!-- <el-select
						v-model="value"
						placeholder="Select"
						style="width: 227px; margin-right: 13px"
						size="small"
					>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
					<el-select v-model="value" placeholder="Select" style="width: 227px" size="small">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select> -->
				</div>
			</div>
			<div class="h_body">
				<div class="empty" v-if="false">{{ $t('card_detail.empty') }}</div>
				<div class="content" v-else>
					<div class="h_item" v-for="(item, index) in cardList" :key="index">
						<div class="h_item_left">
							<div class="state">
								<!-- <img
									v-if="index == 0"
									src="../../assets/wallet/state_icon2.png"
									alt=""
									class="state_icon"
								/> -->
								<img src="../../assets/wallet/state_icon3.png" alt="" class="state_icon" />
							</div>
							<div class="font">
								<div class="font1">
									<img src="../../assets/card/icon.png" alt="" />
									<span> {{ cardInfo.cardNo }} </span>
								</div>
								<div class="font2">
									<span v-if="locale == 'zh'">
										{{ item.type == 'TransferIn' ? '充值' : '扣除' }}
									</span>
									<span v-else>
										{{ item.type }}
									</span>
								</div>
							</div>
						</div>
						<div class="h_item_right">
							<div class="money">
								<div class="type">
									<!-- <span v-if="index == 0" style="color: red">失败</span> -->
									<span style="color: #19D079;">{{ $t('card_detail.success') }}</span>
								</div>
								<div class="number">{{ item.amount }} {{ item.currency }}</div>
							</div>
							<div class="date">{{ dayjs(item.transactionTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog
			v-model="dialogVisible"
			:title="$t('card_detail.dia_title')"
			width="500"
			:before-close="handleClose"
		>
			<span>{{ $t('card_detail.dia_font1') }}</span>
			<span style="font-weight: 600">{{ cardInfo.cardNo }}</span>
			<span>{{ $t('card_detail.dia_font2') }}</span>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogVisible = false">{{ $t('card_detail.cancel') }}</el-button>
					<el-button type="primary" @click="setSuspend">
						{{ $t('card_detail.confirm') }}
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import QrcodeVue from 'qrcode.vue';
import {
	cardInfoApi,
	getTradeListApi,
	getCvvApi,
	suspendCardApi,
	rechargeCardApi,
} from '@/api/index';
import { dayjs } from 'element-plus'
// 扩展插件
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const dialogVisible = ref(false);
const router = useRouter();
const route = useRoute();
let state = reactive({});
const value = ref('');
const cvv = ref('');
const { locale } = useI18n();

const options = [];

let cardInfo = ref({});

// 加载ui
const loadingInstance1 = ElLoading.service({ fullscreen: true });
// api 请求
let body = {
	cardId: route.query.cardId,
	balanceId: route.query.balanceId,
};
cardInfoApi(body)
	.then((res) => {
		// 关闭加载
		loadingInstance1.close();
		if (res.code == 200) {
			cardInfo.value = res.data;
			cardInfo.value.balance = cardInfo.value.balance.toString().match(/^\d+(?:\.\d{0,2})?/)[0]
			// console.log(res.data);
			// console.log(res.data.balance);
			// console.log(Math.floor(res.data.balance * 100) / 100);
			// console.log(res.data.balance.toString().match(/^\d+(?:\.\d{0,2})?/))
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
		loadingInstance1.close();
	});

let cardList = ref([]);
// api 请求
getTradeListApi({
	cardId: route.query.cardId,
})
	.then((res) => {
		if (res.code == 200) {
			cardList.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {});

const getCvv = () => {
	// 加载ui
	const loadingInstance2 = ElLoading.service({ fullscreen: true });
	getCvvApi({
		cardId: route.query.cardId,
	})
		.then((res) => {
			// 关闭加载
			loadingInstance2.close();
			cvv.value = res.data.cvv;
		})
		.catch(() => {
			// 关闭加载
			loadingInstance2.close();
		});
};
const toTopup = () => {
	router.push({
		path: '/admin/cardsTopup',
		query: {
			cardId: route.query.cardId,
		},
	});
};
const setSuspend = () => {
	// 加载ui
	const loadingInstance3 = ElLoading.service({ fullscreen: true });
	suspendCardApi({
		cardId: route.query.cardId,
	})
		.then((res) => {
			// 关闭加载
			dialogVisible.value = false;
			loadingInstance3.close();
			if (res.code == 200) {
				if (locale.value == 'en') {
					ElMessage.success('Successful operation');
				} else {
					ElMessage.success('操作成功');
				}
				router.push('/admin/cards');
			} else {
				ElMessage.error(res.msg);
			}
		})
		.catch(() => {
			// 关闭加载
			dialogVisible.value = false;
			loadingInstance3.close();
		});
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.wallet_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.wallet {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.title {
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				padding-left: 25px;
				display: flex;
				align-items: center;
			}
			.w_body {
				// height: 202px;
				padding: 47px 52px;
				display: flex;
				align-items: center;
				.w_left {
					width: 400px;
					height: 253px;
					margin-right: 70px;
					.card1 {
						width: 400px;
						height: 253px;
						background: url('../../assets/card/detail_card1.png');
						background-size: 400px 253px;
						background-repeat: no-repeat;
						position: relative;
						.number {
							position: absolute;
							top: 52px;
							left: 28px;
							font-weight: 600;
							font-size: 29px;
							color: #ffffff;
							letter-spacing: 1px;
						}
						.date {
							color: #ffffff;
							position: absolute;
							top: 112px;
							left: 128px;
						}
						.cvv {
							color: #ffffff;
							position: absolute;
							bottom: 32px;
							left: 28px;
							font-size: 22px;
							font-weight: 600;
						}
					}
					.card2 {
						width: 481px;
						height: 264px;
						background: url('../../assets/card/detail_card2.png');
					}
				}
				.w_right {
					display: flex;
					// align-items: center;
					flex-direction: column;
					.font {
						font-size: 21px;
						color: #666666;
						margin-bottom: 8px;
					}
					.usdt {
						font-weight: normal;
						font-size: 40px;
						color: #333333;
						margin-bottom: 70px;
						span {
							display: inline-block;
							font-size: 27px;
							margin-left: 4px;
						}
					}
					.button_box {
						width: 100%;
						display: flex;
						align-items: center;
						button {
							min-width: 180px;
							height: 63px;
							border-radius: 5px;
							border: 1px solid #e4e4e4;
							font-size: 24px;
							color: #333333;
							background: #ffffff;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							img {
								width: 35px;
								margin-right: 11px;
							}
						}
						.button1 {
							// background: #333333;
							// border: 0;
							// margin-right: 20px;
							// color: #ffffff;
							margin: 0 37px;
						}
					}
				}
			}
		}
		.history {
			width: 100%;
			display: flex;
			flex-direction: column;
			background: #ffffff;
			width: 100%;
			min-height: 305px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.h_title {
				padding: 25px 37px 25px 25px;
				width: 100%;
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.h_body {
				display: flex;
				width: 100%;
				.empty {
					width: 100%;
					height: 220px;
					display: flex;
					justify-content: center;
					margin-top: 58px;
					font-size: 17px;
					color: #bbbbbb;
				}
				.content {
					width: 100%;
					min-height: 220px;
					padding: 31px 25px;
					display: flex;

					flex-wrap: wrap;
					.h_item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 573px;
						height: 107px;
						background: #f6f8fc;
						border-radius: 10px;
						padding: 0 12px 0 25px;
						margin: 0 50px 19px 0;
						.h_item_left {
							display: flex;
							.state {
								display: flex;
								align-items: center;
								font-size: 22px;
								color: #333333;
								font-weight: 600;
								img {
									margin-right: 20px;
									width: 53px;
								}
							}
							.font {
								display: flex;
								flex-direction: column;
								// align-items: center;
								font-size: 19px;
								color: #666666;
								padding: 1px 0;
								.font1 {
									img {
										margin-right: 7px;
										width: 21px;
									}
									font-weight: 600;
									margin-bottom: 3px;
									display: flex;
									align-items: center;
								}
							}
						}
						.h_item_right {
							display: flex;
							flex-direction: column;
							font-size: 20px;
							color: #666666;
							align-items: flex-end;
							.date {
								text-align: right;
							}
							.money {
								display: flex;
								align-items: center;
								.type {
									color: #ff3b30;
									margin-right: 11px;
									font-weight: 600;
								}
								.number {
									color: #333333;
									font-weight: 600;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss"></style>
